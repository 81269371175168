import {
    Edit, Form,
    useTranslate, useRecordContext, EditProps, useGetIdentity, NumberInput
} from 'react-admin';

import { useMediaQuery, Box, Card, Theme, Switch } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { isEmptyObject } from "../fields/util";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { EditToolbar, StyledToolbar } from "../fields/EditWithToolbar";

import { FIXED_FORMAT_OPTIONS, NO_FIXED_FORMAT_OPTIONS } from "../fields/MonyField"


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    return errors;
};

export const transFormPaymentData = (data: any) => {
    return {
        ...data,
        note: data?.note === '<br/><br/><br/><br/>' ? null : data?.note,
        user: isEmptyObject(data?.user?.id) ? null : { id: data.user.id },
        websites: data.ws
    };
}

interface Props extends EditProps<any> {
}

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();
    return (<span>
        {isEmptyObject(record?.id) ? translate('resources.payments.name') : ``}
    </span>)
}

const EntityEdit = (props: Props) => {
    const theme = useTheme();

    return (<Edit redirect={"show"} title={<EntityTitle />} transform={transFormPaymentData} {...props} component="div">
        <FormPayment />
    </Edit>)
}

// {
//     "id" : 35629551,
//     "note" : "",
//     "rate" : 0.8,
//     "rateStartDate" : "2023-03-13",
//     "rateEndDate" : "2026-03-13",
//     "lockDate" : null,
//     "rates" : null
// }

const getDefaultNote = (record: any) => {
    return record?.note ? record.note : '<br/><br/><br/><br/>';
}

export const FormPayment = (props: Props) => {
    const record = useRecordContext();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');
    const [print, setPrint] = useState(true)
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const recordFix = useMemo(() => {

        const WEB = {} as any;
        record?.websites?.forEach((w: any) => {
            if (typeof WEB[w.domain] === "undefined") {
                WEB[w.domain] = { "domain": w.domain, "websiteId": w.websiteId, "deduction": 0 }
            }
            if (w.type === 'deduction') {
                WEB[w.domain]['deduction'] = -w.deduction * record.rate;
            } else {
                WEB[w.domain]['revenue'] = w.revenue * record.rate;
                WEB[w.domain]['pubRevenue'] = w.pubRevenue * record.rate;
            }
        })
        return { ...record, note: getDefaultNote(record), ws: Object.keys(WEB).map(k => WEB[k]) };
    }, [record])
    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate('/payments');
    }, [navigate]);
    const formatter = new Intl.NumberFormat((record.currency === "vnd" ? NO_FIXED_FORMAT_OPTIONS : FIXED_FORMAT_OPTIONS) as any)

    const totalRevenue = record?.websites?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue.pubRevenue) * record.rate, 0)

    function createData(
        name: string,
        calories: number,
        fat: number,
        carbs: number,
        protein: number,
    ) {
        return { name, calories, fat, carbs, protein };
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const label = { inputProps: { 'aria-label': 'Print format' } };

    return (<>
        {isAdmin && <StyledToolbar>
            <span>Print format:</span> <Switch {...label} checked={print} onChange={() => setPrint(!print)} />

        </StyledToolbar>}
        <Form validate={validateForm} record={recordFix}>
            <Card className='print_area invoice'>
                <Box sx={{ padding: isXSmall ? 2 : 10 }}>
                    <div>
                        <h3>INVOICE #{record.periodName.substring(0, 3).toUpperCase()}{record.date.replaceAll('-', '')}{record.user.id}{record.currency.toUpperCase()}</h3>
                        {record.currency === 'usd' && <div>
                            <p>Cong Ty Co Phan Z HOLDINGS</p>
                            <p>2th Floor StarTower, 68 Duong Dinh Nghe Street, Cau Giay District, Hanoi</p>
                            <p>VAT: 0109373585</p>
                            <p>Email: tha@zmedia.info</p>

                        </div>}
                        {record.currency === 'vnd' && <div>
                            <p>CÔNG TY CỔ PHẦN Z HOLDINGS</p>
                            <p>Tầng 2, Tòa Nhà StarTower, Đường Dương Đình Nghệ, Phường Yên Hòa, Quận Cầu Giấy, TP Hà Nội</p>
                            <p>VAT: 0109373585</p>
                            <p>Email: tha@zmedia.info</p>
                        </div>}
                        <div className='row'>
                            <div className='col'>
                                <h4>Invoice:</h4>
                                <div className='title'>
                                    <strong>Balance due:</strong>
                                    <span className={record.currency === 'usd' ? 'enMoney' : 'viMoney'}>{formatter.format(totalRevenue)}</span>
                                </div>
                                <div className='title'>
                                    <strong>Invoice date:</strong>
                                    <span>
                                        {record.createdAt}
                                    </span>
                                </div>
                                <div className='title'>
                                    <strong>Due date:</strong>
                                    <span>
                                        {record.createdAt}
                                    </span>
                                </div>
                                <div className='title'>
                                    <strong>Duration:</strong>
                                    <span>
                                        {record.date} - {record.endDate}
                                    </span>
                                </div>
                                <div className='title'>
                                    <strong>Currency:</strong>

                                    <span>
                                        {record.currency}
                                    </span>
                                </div>
                                <div className='title'>
                                    <strong>Bill to: </strong>
                                    <span>
                                        {record.user.displayName}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='table'>
                        <TableContainer >
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Website</StyledTableCell>
                                        <StyledTableCell align="right"><span className={record.currency === 'usd' ? 'enMoney' : 'viMoney'}>Revenue</span></StyledTableCell>
                                        <StyledTableCell align="right"><span className={record.currency === 'usd' ? 'enMoney' : 'viMoney'}>Deduction</span></StyledTableCell>
                                        <StyledTableCell align="right"><span>Rate shared</span></StyledTableCell>
                                        <StyledTableCell align="right"><span className={record.currency === 'usd' ? 'enMoney' : 'viMoney'}>Payment</span></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recordFix.ws.map((row, index) => (
                                        <StyledTableRow key={row.domain}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.domain.trim()}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{formatter.format(row.revenue)}</StyledTableCell>
                                            {(print || !isAdmin) && <StyledTableCell align="right">{formatter.format(row.deduction)}</StyledTableCell>}
                                            {!print && isAdmin && <StyledTableCell align="right" className='not-show-on-p'><NumberInput variant='outlined' source={`ws.${index}.deduction`} fullWidth /></StyledTableCell>}
                                            <StyledTableCell align="right">{formatter.format(row.pubRevenue / row.revenue)}</StyledTableCell>
                                            <StyledTableCell align="right">{formatter.format(row.pubRevenue - row.deduction * row.pubRevenue / row.revenue)}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    <StyledTableRow key={`total`}>
                                        <StyledTableCell component="th" scope="row" colSpan={4}>
                                            Total
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{formatter.format(totalRevenue)}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                    <div className='row'>
                        <div className='col' dangerouslySetInnerHTML={{ __html: `<h4>Bank details:</h4> ${record.address}` }}></div>
                        {record.currency === 'usd' &&
                            <div className='col right'>
                                <p>Note: The revenue includes 10% VAT</p>
                            </div>}
                        {record.currency === 'vnd' &&
                            <div className='col right'>
                                <p>Doanh thu bao gồm 10% thuế VAT</p>
                            </div>}
                    </div>
                </Box>
            </Card >
            {isAdmin && <EditToolbar field={"domain"} />}
        </Form>
    </>)
}


export default EntityEdit;
