import * as React from 'react';
import {
    useGetIdentity,
    useGetList,
    List,
    Datagrid,
    TextField,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ReferenceInput,
    AutocompleteArrayInput,
    Count,
    NumberField, useListContext
} from 'react-admin';
import AdvertiserReferenceField from "../advertisers/AdvertiserReferenceField";
import { FactorChoices, isEmptyArray, isEmptyObject } from "../fields/util";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Divider, Tab, Tabs } from "@mui/material";
import RowStyle, { ListProps } from "../fields/RowStyle";
import PublisherReferenceField from "../publishers/PublisherReferenceField";


const ListActions = () => {
    return (
        <TopToolbar>
            <a href={"/#/settings-advertiser/create"}>Add</a>
            <FilterButton />
        </TopToolbar>
    );
};

const AdvertiserFactor = ({ title = 'Config factor for advertiser' }) => {
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const [localState, setLocalState] = useState({ userId_in: [], sourceId_in: [] });
    const isSuperAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');
    const entityFilters = [
        <ReferenceInput source="userId_in" reference="users" alwaysOn={true}
            filter={isEmptyArray(localState?.userId_in) ? {} : { orIds: localState.userId_in }}>
            <AutocompleteArrayInput variant={"outlined"}
                sx={{ minWidth: "200px" }}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.login}`
                        : ''
                }
                filterToQuery={(q) => {
                    return { login_contains: q, orIds: localState.userId_in }
                }}
                name={"userId_in"}
            />
        </ReferenceInput>,

        <ReferenceInput
            alwaysOn
            source="sourceId_in"
            reference="sources"
            filter={isEmptyArray(localState.userId_in) ? {} : { userId_in: localState.userId_in }}>
            <AutocompleteArrayInput
                alwaysOn
                variant={"outlined"}
                sx={{ minWidth: "200px" }}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.name}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        name_contains: q, orIds: localState.userId_in,
                    }
                }}
                name={"sourceId_in"}
            />
        </ReferenceInput>,
    ];
    if (!isSuperAdmin) return null;

    return (
        <List resource={"configs"}
            filters={entityFilters}
            filterDefaultValues={{ "type.Equals": "factor" }} actions={<ListActions />}>
            <TabledData setFiltered={setLocalState} />
        </List>
    );
};


const TabledData = (props: ListProps) => {
    const listContext = useListContext();

    const { setFiltered } = props;
    const { filterValues, setFilters, displayedFilters } = listContext;
    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, type_equals: value },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );


    useEffect(() => {
        setFiltered(filterValues)
    }, [filterValues]);

    return (<Fragment>
        <Tabs
            variant="fullWidth"
            centered
            value={isEmptyObject(filterValues?.type_equals) ? "factor" : filterValues?.type_equals}
            indicatorColor="primary"
            onChange={handleChange}
        >
            {FactorChoices.map(choice => (
                <Tab
                    key={choice.id}
                    label={
                        <span>
                            {choice.name} (
                            <Count
                                filter={{
                                    ...filterValues,
                                    'type_equals': choice.id,
                                }}
                                sx={{ lineHeight: 'inherit' }}
                            />
                            )
                        </span>
                    }
                    value={choice.id}
                />
            ))}
        </Tabs>
        <Divider />

        <Datagrid rowClick={(rw: any) => {
            // window.location.href = `/#/settings-advertiser/${rw}`;
            return `/settings-advertiser/${rw}`;
        }}>
            {filterValues.type_equals == "factor" && <AdvertiserReferenceField source="name" />}
            {filterValues.type_equals == "factor-user" && <PublisherReferenceField source="name" />}
        </Datagrid>
    </Fragment>)
}

export default AdvertiserFactor;