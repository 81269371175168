import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        analytic: {
            "area": "Breakdown",
            "revenue": "Revenue",
            "impression": "impresion",
        },
        dashboard: {
            welcome: {
                title: 'Welcome to ZHolding.vn',
                subtitle:
                    "ZHolding is a unit with more than 10 years of experience in operating and developing in the field of online advertising.\n",
                ra_button: 'Learn more!',
                demo_button: 'Hide',
            },
            "website_revenue": "Monthly Website Revenue Breakdown",
            "website_request": "Websites requests this month",
            "area": "Daily breakdown",
            "monthly_revenue": "Monthly revenue",
            "monthly_requests": "Monthly requests",
            Column: {
                AD_EXCHANGE_TOTAL_REQUESTS: "Requests",
                AD_EXCHANGE_LINE_ITEM_LEVEL_IMPRESSIONS: "Impressions",
                AD_EXCHANGE_LINE_ITEM_LEVEL_CLICKS: "Clicks",
            }
        },
    },
    resources: {
        "payments": {
            "name": "Invoice",
            filters: {
                tmp_userId_in: "Publishers",
                tmp_date_greaterThanOrEqual: "From date",
                tmp_date_lessThanOrEqual: "To date",
            },
            fields: {
                'user.id': "Publisher",
            }
        },
        "configs": {
            "menu": "Settings",
            "setting-date": "Settings Date",
            "setting-adsense": "Adsense accounts",
            "advertiser-factor": "Advertiser factor",
            "from": "From",
            "to": "To"
        },
        "codes": {
            name: "Embed code",
            filters: {
                name_contains: "Search name"
            },
            fields: {
                "configJS.logo.value": "Logo"
            }
        },
        "analytic": {
            "name": "Analytic"
        },
        "report-ad-exchanges/report": {
            filters: {
                tmp_dateReported_greaterThanOrEqual: "From date",
                tmp_dateReported_lessThanOrEqual: "To date",
                tmp_groupBy: "Group by",
                tmp_userId_in: "Publishers",
                tmp_sourceId_in: "Advertisers",
                tmp_source_like: "Search account",
                tmp_adUnitId_in: "AdUnits",
                tmp_websiteId_in: "Websites",
                tmp_param_like: "Type",
                tmp_type_equal: "Type"
            },
            fields: {
                tmp_dateReported_greaterThanOrEqual: "From date",
                tmp_dateReported_lessThanOrEqual: "To date",
                tmp_groupBy: "Group by",
                tmp_userId_in: "Publishers",
                tmp_sourceId_in: "Advertisers",
                tmp_adUnitId_in: "AdUnits",
                tmp_websiteId_in: "Websites",
                tmp_source_like: "Search account",
                tmp_param_like: "Type",
                tmp_type_equal: "Type",
                user: 'Publisher',
                website: 'Website',
                matchedECPM: "Matched ECPM",
                ECPM: "ECPM",
                ACPM: "aCPM",
                adRequests: "Requests",
                matchedRequests: "Matched Requests",
                clicks: "Clicks",
                coverage: "Coverage",
                estimatedRevenue: "Total Revenue",
                estimatedRevenueVnd: "Total Revenue",
                revenue: "Pub Revenue",
                revenueVnd: "Pub Revenue",
                rate: "Fill rate"
            }
        },
        "report-ad-exchanges/analytic": {
            filters: {
                tmp_date_greaterThanOrEqual: "From date",
                tmp_date_lessThanOrEqual: "To date",
                tmp_groupBy: "Group by",
                tmp_userId_in: "Publishers",
                tmp_sourceId_in: "Advertisers",
                tmp_adUnitId_in: "AdUnits",
                tmp_websiteId_in: "Websites",
                tmp_param_like: "Type",
                tmp_type_equal: "Type"
            },
            fields: {
                tmp_date_greaterThanOrEqual: "From date",
                tmp_date_lessThanOrEqual: "To date",
                tmp_type: "Analytic",
                tmp_groupBy: "Group by",
                tmp_userId_in: "Publishers",
                tmp_sourceId_in: "Advertisers",
                tmp_adUnitId_in: "AdUnits",
                tmp_websiteId_in: "Websites",
                tmp_param_like: "Type",
                tmp_type_equal: "Type",
                user: 'Publisher',
                website: 'Website',
                matchedECPM: "Matched ECPM",
                ECPM: "ECPM",
                ACPM: "aCPM",
                adRequests: "Requests",
                matchedRequests: "Matched Requests",
                clicks: "Clicks",
                coverage: "Coverage",
                estimatedRevenue: "Total Revenue",
                estimatedRevenueVnd: "Total Revenue",
                revenue: "Pub Revenue",
                revenueVnd: "Pub Revenue",
                rate: "Fill rate"
            }
        },
        report: {
            name: "Report",
            total: "Total",
            note: "The revenue includes 10% VAT",
            filters: {
                dateReported_greaterThanOrEqual: "From date",
                dateReported_lessThanOrEqual: "To date",
                tmp_userId_in: "Publishers",
                tmp_sourceId_in: "Advertisers",
                this_month: "This month",
                last_month: "Last month",
                last_2_month: "Last 2 month",
                this_week: "This week",
                last_week: "Last week",
                today: "Today",
                yesterday: "Yesterday",
                "choose_time": "Quick time",
                "this_year": "This year"
            }
        },
        websites: {
            name: 'Websites',
            fields: {
                commands: 'Orders',
                'user.id': "Publisher",
                userId_in: "Search publishers"
            },
            filters: {
                last_visited: 'Last visited',
                rateStartDate_greaterThanOrEqual: 'Rate start greater than',
                rateEndDate_greaterThanOrEqual: 'Rate end greater than',
                rateStartDate_lessThan: 'Rate start greater than',
                rateEndDate_lessThan: 'Rate end greater than',
                lockDate: 'Start read data date',
                rateStartDate: "Rate start date",
                rateEndDate: "Rate end date",
                startDate: "Start date",
                domain_contains: "Search domain"
            },
            fieldGroups: {
                info: 'Information',
                config: 'Configuration'
            },
            page: {
                delete: 'Delete website',
                create: 'Create website',
            },
            errors: {},
        },
        "admin/users": {
            filters: {
                domain: "Search domain",
                email_contains: "Search email"
            }
        },
        publishers: {
            name: 'Publishers',
            fields: {
                commands: 'Orders',
            },
            filters: {
                last_visited: 'Last visited',
            },
            fieldGroups: {
                name: 'Information',
                login: 'Login',
                websites: 'Websites',
                manage: 'Manage users',
                delete: 'Delete'
            },
            page: {
                delete: 'Delete publisher',
                create: 'Create publisher'
            },
            errors: {},
        },
        sources: {
            fields: {
                'user.id': "Publisher",
                userId_in: "Search publishers"
            },
            filters: {
                idx_contains: "Search IDX",
                name_contains: "Search Name",
                lastUpdateLog_contains: "Search Log",
                lastUpdateData_greaterThanOrEqual: "Log date greater than",
                lastUpdateData_lessThan: "Log date less than"
            },
        },
        advertisers: {
            name: 'Advertisers',
            fields: {
                user: 'Publisher',
                'user.id': "Publisher",
                userId_in: "Search publishers"
            },
            filters: {
                idx_contains: "Search IDX",
                name_contains: "Search Name",
                lastUpdateLog_contains: "Search Log",
                lastUpdateData_greaterThanOrEqual: "Log date greater than",
                lastUpdateData_lessThan: "Log date less than",
                startDate: "Start date"
            },
            fieldGroups: {
                info: 'Information',
                config: 'Configuration'
            },
            page: {
                delete: 'Delete advertiser',
                create: 'Create advertiser'
            },
            errors: {},
        },
        "ad-units": {
            fields: {
                websiteId_in: "Search websites",
                sourceIdFilter_in: "Search advertisers",
                "source": "Advertiser",
            },
            filters: {
                display_contains: "Search display",
                websiteId_in: "Search websites",
                sourceIdFilter_in: "Search advertisers",
                "source": "Advertiser",
            },
        },
        adunits: {
            name: 'AdUnits',
            help: "Select AdUnits below to add to the configuration sections",
            help2: "Select advertisers for custom AdUnit",
            fields: {
                websiteId_in: "Search websites"
            },
            filters: {
                last_visited: 'Last visited',
                display_contains: "Search display",
                websiteId_in: "Search websites"
            },
            fieldGroups: {
                info: 'Information',
                config: 'Configuration'
            },
            page: {
                delete: 'Delete adUnit',
                create: 'Create adUnit'
            },
            errors: {},
        },
    },
};

export default customEnglishMessages;
