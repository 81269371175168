import * as React from 'react';
import {
    List,
    Count,
    Datagrid,
    TextField,
    useListContext,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    ReferenceInput,
    AutocompleteArrayInput,
    CreateButton, useGetIdentity
} from 'react-admin';

import { useMediaQuery, Divider, Tabs, Tab, Theme, Drawer } from '@mui/material';

import { Fragment, useCallback, useEffect, useState } from 'react';
import DatePickerFormatable from "../fields/DatePickerFormatable";
import PublisherReferenceField from "../publishers/PublisherReferenceField";
import { matchPath, useLocation } from 'react-router-dom';
import WebsiteEdit from './Edit'
import RowStyle, { ListProps } from "../fields/RowStyle";
import { isEmptyArray, isEmptyObject } from "../fields/util";


const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
    </TopToolbar>
);


const EntityList = () => {
    const location = useLocation();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const [localState, setLocalState] = useState({ userId_in: [] });
    const entityFilters = [
        <ReferenceInput source="userId_in" reference="users" alwaysOn={!isXSmall}
            filter={isEmptyArray(localState?.userId_in) ? {} : { orIds: localState.userId_in }}>
            <AutocompleteArrayInput variant={"outlined"}
                sx={{ minWidth: "200px" }}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.login}`
                        : ''
                }
                filterToQuery={(q) => {
                    return { login_contains: q, orIds: localState.userId_in }
                }}
                name={"userId_in"}
            />
        </ReferenceInput>,
        <DatePickerFormatable alwaysOn source="tmp_date_greaterThanOrEqual" />,
        <DatePickerFormatable alwaysOn source="tmp_date_lessThanOrEqual" />,
    ];

    const match = matchPath('/billings/:id', location.pathname);
    const editId = !!match
        ? parseInt((match as any).params.id, 10)
        : undefined;

    return (
        <>
            <List
                filterDefaultValues={{ status_equals: 'activate' }}
                sort={{ field: 'id', order: 'DESC' }}
                perPage={25}
                filters={entityFilters}
                actions={<ListActions />}
                title="Invoice"
            >
                <TabbedDataGrid editId={editId} isXSmall={isXSmall} setFiltered={setLocalState} />
            </List>
            <Drawer
                variant="persistent"
                open={!!match}
                anchor="right"
                sx={{ zIndex: 100 }}
            >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {!!match && (
                    <WebsiteEdit
                        id={(match as any).params.id}
                    />
                )}
            </Drawer>
        </>
    )
};
const tabs = [
    { id: 'activate', name: 'Activate' },
    { id: 'deactivate', name: 'Incoming' },
];


const TabbedDataGrid = (props: ListProps) => {
    const { editId, isXSmall, setFiltered } = props;
    const listContext = useListContext();

    const { filterValues, setFilters, displayedFilters } = listContext;
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');


    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status_equals: value },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    useEffect(() => {
        setFiltered(filterValues)
    }, [filterValues]);

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={isEmptyObject(filterValues?.status_equals) ? "activate" : filterValues?.status_equals}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        'status_equals': choice.id,
                                    }}
                                    sx={{ lineHeight: 'inherit' }}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <>
                {['activate', 'deactivate'].includes(isEmptyObject(filterValues?.status_equals) ? "activate" : filterValues?.status_equals) && (
                    <Datagrid rowStyle={RowStyle(editId)}
                        rowClick="edit" optimized bulkActionButtons={false}>
                        <TextField source="date" />
                        <TextField source="endDate" />
                        <TextField source="currency" />
                        <TextField source="rate" />
                        <TextField source="periodName" />
                        <PublisherReferenceField link={false} />
                    </Datagrid>
                )}
            </>
        </Fragment>
    );
};

export default EntityList;
