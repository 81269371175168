import ReceiptIcon from '@mui/icons-material/Receipt';

import List from './List';
import Create from './Create'
import Edit from './Edit'

export default {
    list: List,
    edit: Edit,
    icon: ReceiptIcon,
};
